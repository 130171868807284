import React from 'react';
import { Router } from '@reach/router';
import Page from 'components/Page';
import ContentBlock from 'components/Content/ContentBlock';
import { Grid } from 'react-flexbox-grid';
import ColorBlock from 'components/Content/ColorBlock';
import GuidelinesPage from 'components/HcpRdnGuidelinesPage';
import PrivateRoute from 'components/private-route';
import Login from 'components/login';
import { Layout, Seo } from 'components';

const App = () => {
  const pageTitle = '';
  const pageDescription = '';
  return (
    <Layout indication="nutritionHcp" className="nutrition-hcp-home">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <Page
        pageID="nutrition-hcp-home"
        siteName="Nutrition Direct"
        pageTitle="NUTRITION DIRECT™ | HCP"
        pageKeywords="Nascobal, Vitamin B12, Nasal Spray"
        pageDescription=""
      >
        <ContentBlock id="nutrition-direct-rdn-guidelines">
          <Grid
            fluid
            style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          >
            <ColorBlock white>
              <Router basepath="nutritiondirect/hcp/rdn-guidelines">
                <PrivateRoute path="/view" component={GuidelinesPage} />
                <Login path="/" />
              </Router>
            </ColorBlock>
          </Grid>
        </ContentBlock>
      </Page>
    </Layout>
  );
};
// export default windowSize(App);
export default App;
