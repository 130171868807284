import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import guidelines from '../../../assets/images/asmbs-guidelines.png';
import SourceEmitter from '../../libs/emitter';

import './rdn-guidelines.scss';
class HCPProcedureVideoPage extends React.Component {
  onCtaClick = () => {
    SourceEmitter.emit('HcpExitModalTriggerEvent', {
      link: 'https://eorder.sheridan.com/3_0/app/orders/9441/article.php',
      indication: 'nutrition-direct',
    });
  };

  render() {
    return (
      <div>
        <Row end="md" middle="md">
          <Col xs={12} md={4} onClick={this.onCtaClick}>
            <img className="rdn-thumb" src={guidelines} />
          </Col>
          <Col xs={12} md={6}>
            <div className="guideliness-container" onClick={this.onCtaClick}>
              <p>
                American Society for Metabolic and Bariatric Surgery Integrated
                Health Nutritional Guidelines for the Surgical Weight Loss
                Patient 2016 Update: Micronutrients
              </p>
              <p>
                Julie Parrott, M.S., R.D.N., Laura Frank, Ph.D., M.P.H., R.D.N.,
                C.D., Rebecca Rabena, R.D.N., L.D.N., Lillian Craggs-Dino,
                D.H.A., R.D.N., L.D.N., Kellene A. Isom, M.S., R.D.N., L.D.N.,
                Laura Greiman, M.P.H., R.D.N.
              </p>
              <a
                href="javascript:void(0);"
                onClick={this.onCtaClick}
                className="yellow-button"
              >
                DOWNLOAD NOW
              </a>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default HCPProcedureVideoPage;
