import React from 'react';
import { navigate } from '@reach/router';
import { isLoggedIn } from '../../../services/auth';

const PrivateRoute = ({ component, location, ...rest }) => {
  const Component = component;
  if (
    !isLoggedIn() &&
    location.pathname !== `/nutritiondirect/hcp/rdn-guidelines/`
  ) {
    navigate('/nutritiondirect/hcp/rdn-guidelines/');
    return null;
  }
  return <Component {...rest} />;
};
export default PrivateRoute;
