import React from 'react';
import Helmet from 'react-helmet';
import { Row, Col } from 'react-flexbox-grid';
import SourceEmitter from '../../libs/emitter';
import Modal from 'react-awesome-modal';

import './index.scss';
import favIcon from '../../../assets/images/favicon.ico';

let Subscription = null;
let awayURL = '';
class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      visible: false,
      exitModalVisible: false,
      exitModalBodyText: '',
      indicationText: '',
    };
    this.closeModal = this.closeModal.bind(this);
  }

  openLink(link) {
    this.setState({
      visible: false,
    });
    setTimeout(() => {
      window.location.replace(link);
    }, 100);
  }

  openModal() {
    this.setState({
      visible: true,
    });
  }

  openExitModal(_indication) {
    this.setState({
      exitModalVisible: true,
    });
    _indication === 'nutrition-direct'
      ? this.setState({ indicationText: 'Nutrition Direct<sup>&trade;</sup>' })
      : this.setState({ indicationText: 'NASCOBAL<sup>&reg;</sup>' });
  }

  closeModal() {
    this.setState({
      visible: false,
      exitModalVisible: false,
    });
  }

  componentWillMount() {
    // For HCP Popup
    Subscription = SourceEmitter.addListener('HcpModalTriggerEvent', data => {
      awayURL = data;
      this.openModal();
    });
    // For HCP Popup
    Subscription = SourceEmitter.addListener(
      'HcpExitModalTriggerEvent',
      data => {
        awayURL = data.link;
        this.openExitModal(data.indication);
      },
    );
  }

  componentWillUnmount() {
    Subscription && Subscription.remove();
  }

  render() {
    return (
      <div id={this.props.pageID} className="page offset">
        <Helmet>
          <title>{this.props.pageTitle}</title>
          <meta name="description" content={this.props.pageDescription} />
          <meta name="keywords" content={this.props.pageKeywords} />
          <meta name="facebook-domain-verification" content="dshweunotxqfncaqcx20mjiss3iyp1" />
          <link rel="icon" type="image/png" href={favIcon} sizes="16x16" />

        </Helmet>
        {this.props.children}
        <div>
          <Modal
            visible={this.state.visible}
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <Row className="hcp-modal">
              <Col xs={12}>
                <div className="modal-content">
                  <h2>HEALTHCARE PROFESSIONALS</h2>
                  <p>
                    The information contained in this section of the site is
                    intended for U.S. Healthcare Professionals only. <br />
                    Click continue if you are a healthcare professional.
                  </p>
                  <div className="button-block">
                    <button onClick={() => this.openLink(awayURL)}>
                      CONTINUE
                    </button>{' '}
                    <button onClick={this.closeModal}>CANCEL</button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal>
        </div>
        {/* exit modal */}
        <div>
          <Modal
            visible={this.state.exitModalVisible}
            effect="fadeInUp"
            onClickAway={() => this.closeModal()}
          >
            <Row className="hcp-modal">
              <Col xs={12}>
                <div className="modal-content">
                  <p>
                    You are now leaving the{' '}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.indicationText,
                      }}
                    />{' '}
                    website. <nobr>Endo Pharmaceuticals</nobr> is not
                    responsible for the <br />
                    content of linked sites.
                  </p>
                  <div className="button-block">
                    <button onClick={() => this.openLink(awayURL)}>
                      CONTINUE
                    </button>{' '}
                    <button onClick={this.closeModal}>CANCEL</button>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Page;
